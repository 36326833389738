import { Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useFilterModal } from './FilterModal.context';
import { CheckboxDropdownProps, Chip, FilterItem, SelectedFilterState } from './FilterModal.types';
import { applySupplierFilter, buildRegionCheckboxDropdownItems, buildSectorsCheckboxDropdownItems, buildSuppliersCheckboxDropdownItems, isAllSuppliersSelected, mapSuppliersDataToFilterItems } from './FilterModal.utils';
import { AuthContext } from '../../../modules/auth';
import { CheckboxItemType } from '../../molecules/CheckboxItem/CheckboxItem.types';

const useFilterModalPresenter = () => {
  const { isOpen, closeModal } = useFilterModal();
  const { isAdmin } = useContext(AuthContext);
  const { sectorsData, regionsData, rawSuppliersData, filterState, updateFilterState } = useFilterModal();

  const {
    year,
    selectedRegions: existingSelectedRegions,
    selectedSectors: existingSelectedSectors,
    selectedSuppliers: existingSelectedSuppliers,
  } = filterState;

  const [selectedRegions, setSelectedRegions] = useState<FilterItem[]>([]);
  const [selectedSectors, setSelectedSectors] = useState<FilterItem[]>([]);
  const [selectedSuppliers, setSelectedSuppliers] = useState<FilterItem[]>([]);

  const handleCloseModal = useCallback(() => {
    if (selectedSuppliers.length) {
      closeModal();
    }
  }, [selectedSuppliers, closeModal]);

  useEffect(() => {
    if (existingSelectedRegions.length) {
      setSelectedRegions(existingSelectedRegions);
    }
    if (existingSelectedSectors.length) {
      setSelectedSectors(existingSelectedSectors);
    }
    if (existingSelectedSuppliers.length) {
      setSelectedSuppliers(existingSelectedSuppliers);
    }
  }, [existingSelectedRegions, existingSelectedSectors, existingSelectedSuppliers]);

  const [searchText, setSearchText] = useState<string>('');

  const suppliersData = useMemo(() => applySupplierFilter(selectedSectors, selectedRegions, rawSuppliersData, searchText.trim()), [selectedSectors, selectedRegions, rawSuppliersData, searchText]);

  const isAllSupplierSelected = useMemo(() => isAllSuppliersSelected(selectedSuppliers, suppliersData), [suppliersData, selectedSuppliers]);

  const regionDropdownData: CheckboxDropdownProps = {
    items: buildRegionCheckboxDropdownItems(regionsData),
    type: 'Default',
    selectedItems: selectedRegions,
    setSelectedItems: setSelectedRegions,
  };

  const sectorDropdownData: CheckboxDropdownProps = {
    items: buildSectorsCheckboxDropdownItems(sectorsData),
    type: 'Expandable',
    selectedItems: selectedSectors,
    setSelectedItems: setSelectedSectors,
  };

  const suppliersDropdownData: CheckboxDropdownProps = {
    items: buildSuppliersCheckboxDropdownItems(suppliersData),
    type: isAdmin ? 'Expandable' : 'Default',
    selectedItems: selectedSuppliers,
    setSelectedItems: setSelectedSuppliers,
  };

  const onSearchChange = (text) => {
    setSearchText(text);
  };

  // Function to handle checkbox toggle
  const handleCheckboxToggle = useCallback((
    checkboxItem: CheckboxItemType,
    isSelected: boolean,
    selectedItems: FilterItem[],
    setSelectedItems: Dispatch<SetStateAction<FilterItem[]>>,
  ) => {
    // Check if the checkbox item is "Select All"
    if (checkboxItem.name === 'Select All') {
      // If "Select All" is toggled, either select all suppliers or clear selection
      const allSuppliers: FilterItem[] = mapSuppliersDataToFilterItems(suppliersData, isSelected);
      setSelectedSuppliers(allSuppliers); // Set selected suppliers accordingly
      return; // Exit function
    }

    // If the checkbox is not "Select All"
    if (isSelected) {
      // If the checkbox is currently selected, remove it from selected items
      setSelectedItems(selectedItems.filter(singleItem => singleItem.name !== checkboxItem.name));
    } else {
      // If the checkbox is not selected, add it to selected items
      setSelectedItems([...selectedItems, { name: checkboxItem.name, id: checkboxItem.id, childItems: checkboxItem?.childItems?.map(singleChildItem => singleChildItem.value) }]);
    }
  }, [suppliersData, setSelectedSuppliers]);

  const handleChildCheckboxToggle = (
    value: string,
    parentItem: CheckboxItemType,
    selectedItems: FilterItem[],
    setSelectedItems: Dispatch<SetStateAction<FilterItem[]>>,
  ) => {
    // Find index of parent item in selected items array
    const parentIndex = selectedItems.findIndex(item => item.name === parentItem.name);

    // If parent item not found in selected items array
    if (parentIndex === -1) {
      // Create a new selected item with child item
      const updatedSelectedItem = {
        name: parentItem.name,
        id: parentItem.id,
        childItems: [value],
      };
      setSelectedItems([...selectedItems, updatedSelectedItem]);
    } else {

      // Parent item found in selected items array
      const currentSelectedItem = selectedItems[parentIndex];
      // Check if child item is already selected
      const childItem = currentSelectedItem.childItems?.some(item => item === value);

      // If child item already selected, remove it. Otherwise, add it.
      if (childItem) {
        currentSelectedItem.childItems = currentSelectedItem.childItems?.filter(item => item !== value);
      } else {
        currentSelectedItem.childItems?.push(value);
      }

      // Update selected items array based on child items
      if (currentSelectedItem.childItems?.length) {
        // If there are child items, update parent item in selected items array
        selectedItems.splice(parentIndex, 1, currentSelectedItem);
        setSelectedItems([...selectedItems]);
      } else {
        // If no child items, remove parent item from selected items array
        selectedItems.splice(parentIndex, 1);
        setSelectedItems([...selectedItems]);
      }
    }
  };

  const filteredChipList = useMemo(() => {
    const chipItems: Chip[] = [];
    selectedRegions.forEach(item => {
      chipItems.push({
        name: item.name,
        onClick: () => handleCheckboxToggle(item as CheckboxItemType, true, selectedRegions, setSelectedRegions),
      });
    });

    selectedSectors.forEach((item) => {
      chipItems.push({
        name: `${item.name} ${item.childItems?.length}`,
        onClick: () => handleCheckboxToggle(item as CheckboxItemType, true, selectedSectors, setSelectedSectors),
      });
    });

    return chipItems;
  }, [selectedRegions, selectedSectors, handleCheckboxToggle]);

  const handleApplyFilterClick = () => {
    const filterStateObj: SelectedFilterState = {
      selectedSuppliers: selectedSuppliers,
      selectedSectors: selectedSectors,
      selectedRegions: selectedRegions,
      year: year,
    };
    updateFilterState(filterStateObj);
    handleCloseModal();
  };

  return {
    isOpen,
    closeModal: handleCloseModal,
    sectorDropdownData,
    regionDropdownData,
    suppliersDropdownData,
    onSearchChange,
    searchText,
    handleCheckboxToggle,
    handleChildCheckboxToggle,
    filteredChipList,
    isAllSupplierSelected,
    isEmptyState: suppliersData.length <= 0,
    handleApplyFilterClick,
    applyFilterButtonDisabled: selectedSuppliers.length <= 0,
  };
};

export default useFilterModalPresenter;
